<template>
    <div class="provider-notification">

        <div
            v-if="!pageLoad"
            class="page-loader">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Уведомление поставщика № {{ currentNotification.id }} от {{ currentNotification.date | parseDate }}</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="danger">
                    Экспорт
                </BButton>
                <BButton
                    v-if="currentNotification.status === 'Черновик'"
                    class="ml-3"
                    variant="outline-primary"
                    :to="`${$route.fullPath}/edit`">Редактировать
                </BButton>
            </div>

        </div>

        <b-tabs v-model="tabIndex" card>

            <b-tab title="Информация">

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >№ уведомления
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.id }}</div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Дата
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.date | parseDate }}</div>
                        </div>
                    </b-col>

                </b-row>

                <div class="d-flex table-bordered mb-1">
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Статус согласования с получателем (заказчиком)
                    </div>
                    <div class="px-2 py-1 flex-grow-1">{{ currentNotification.status }}</div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Получатель (заказчик)
                    </div>
                    <div class="px-2 py-1 flex-grow-1">{{ getOrganization(currentNotification.organization_id).name }}</div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Поставщик
                    </div>
                    <div class="px-2 py-1 flex-grow-1">{{ currentNotification && currentNotification.provider && currentNotification.provider.name }}</div>
                </div>

                <b-row>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Дата поставки
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.date_fact_delivery | parseDate }}</div>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >№ договора поставки
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.contract_number }}</div>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Дата договора поставки
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.contract_date | parseDate }}</div>
                        </div>
                    </b-col>

                </b-row>

                <b-row>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Адрес поставки
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.delivery_address }}</div>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Марка, гос. номер авто
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.car_info }}</div>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Телефон водителя
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.driver_phone | VMask('# (###) ###-##-##') }}</div>
                        </div>
                    </b-col>

                </b-row>

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Ответственный
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.responsible_full_name }}</div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Телефон ответственного
                            </div>
                            <div class="px-2 py-1 flex-grow-1">{{ currentNotification.responsible_phone | VMask('# (###) ###-##-##') }}</div>
                        </div>
                    </b-col>

                </b-row>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Комментарий</div>
                    <div class="px-2 py-1 flex-grow-1">{{ currentNotification.organization_comment }}</div>
                </div>

            </b-tab>

            <b-tab title="Позиции">

                <TableFilter
                    :columns="currentNotificationPositionsFields"
                    uniqueKey="table-2"
                    @update-selected-columns="columns = $event"
                    @search="filter = $event"
                    @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
                    @clear="clearFilterData"
                />

                <DefaultTable
                    :data="currentNotification.positions"
                    :fields="currentNotificationPositionsFieldsFiltered"
                    :filter="filter"
                    emptyText="Нет позиций"
                    :loading="positionsLoading"
                    :noLocalSorting="true"
                    @sort-change="sorting"
                    @pagination-change="paginationChange"
                    @per-page-change="perPageChange"
                />

            </b-tab>

        </b-tabs>

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updatePositionsWithFilters(true)"
        />

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TableFilter from "@/components/TableFilter"
import DefaultTable from "@/components/Tables/Default"
import FilterSidebar from "@/components/FilterSidebar"

function initialFilterData() {
    return {
        order_number: '',
        mnemocode: '',
        nomenclature_name: ''
    }
}

export default {
    name: "ProviderNotification",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        id () {
            return this.$route.params.id
        },
        ...mapState({
            currentNotification: state => state.providerNotifications.currentNotification
        }),
        ...mapGetters([
            'getOrganization',
            'getContrAgent'
        ]),
        currentNotificationPositionsFieldsFiltered () {
            let res = []
            this.currentNotificationPositionsFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.currentNotificationPositionsFields
        },
        breadcrumbs () {
            return [
                {title: 'Главная', link: '/'},
                {title: 'Заказ ПО', link: '/'},
                {title: 'Перечень уведомлений поставщика', link: '/provider-notifications'},
                {title: `Уведомление поставщика № ${this.currentNotification.id} от ${this.$options.filters.parseDate(this.currentNotification.date)}`}
            ]
        }
    },
    data: () => ({
        pageLoad: false,
        tabIndex: 0,
        columns: [],
        filter: '',
        filterData: initialFilterData(),
        positionsLoading: false,
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        },
        currentNotificationPositionsFields: [
            {
                key: 'order_number',
                sortable: true,
                label: '№ заказа',
                full: 'Номер заказа',
                openDefault: true
            },
            {
                key: 'order_date',
                sortable: true,
                label: 'Дата заказа',
                full: 'Дата заказа',
                openDefault: true
            },
            {
                key: 'mnemocode',
                sortable: true,
                label: 'Мнемокод',
                full: 'Мнемокод',
                openDefault: true
            },
            {
                key: 'nomenclature_name',
                sortable: true,
                label: 'Номенклатура',
                full: 'Номенклатура',
                openDefault: true
            },
            {
                key: 'count',
                sortable: true,
                label: 'Количество',
                full: 'Количество',
                openDefault: true
            },
            {
                key: 'price_without_vat',
                sortable: true,
                label: 'Цена ₽ (без НДС)',
                full: 'Цена ₽ (без НДС)',
                openDefault: true
            },
            {
                key: 'amount_without_vat',
                sortable: true,
                label: 'Сумма ₽ (без НДС)',
                full: 'Сумма ₽ (без НДС)',
                openDefault: true
            },
            {
                key: 'vat_rate',
                sortable: true,
                label: 'Ставка НДС',
                full: 'Ставка НДС',
                openDefault: true
            },
            {
                key: 'amount_with_vat',
                sortable: true,
                label: 'Сумма ₽ (c НДС)',
                full: 'Сумма ₽ (c НДС)',
                openDefault: true
            }
        ]
    }),
    methods: {
        async getData () {
            this.pageLoad = false
            await Promise.all([
                this.$store.dispatch('getCurrentNotification', this.id),
                this.$store.dispatch('getNotificationPositions', { id: this.id })
            ])
            this.pageLoad = true
        },
        async updatePositionsWithFilters (clearSort) {
            this.positionsLoading = true
            if (clearSort) {
                this.sortingData.sort_field = ''
                this.sortingData.sort_order = ''
                this.sortingData.page = ''
            }
            await this.$store.dispatch('getNotificationPositions', {
                id: this.id,
                filters: {
                    ...this.sortingData,
                    ...this.filterData
                }
            })
            this.positionsLoading = false
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.updatePositionsWithFilters(true)
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updatePositionsWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updatePositionsWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updatePositionsWithFilters()
        }
    },
    mounted () {
        this.getData()
        this.$store.dispatch('checkNotification', {
            entity: 'organization_notifications',
            id: this.id
        })
        this.currentNotificationPositionsFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
